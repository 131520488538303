import { index } from './env';
import { Armando, Index } from './views';

function App() {
	let view = null

	switch (index) {
		case "index":
			view = <Index />
			break
		case "armando":
			view = <Armando />
			break;
		default:
			break;
  	}

	return view;
}

export default App;
