
import { useCallback, useEffect, useState } from "react";
import { greetings } from "./greetings";
import { pez2 } from "../../assets";

const currDate = new Date()

export function Greeting({}) {
    const [state, setState] = useState({
        greeting: greetings[0],
        time: `${currDate.toLocaleDateString("es-MX")} ${currDate.toLocaleTimeString("es-MX")}`
    })

    const timeShuffle = useCallback(() => {
        const index = greetings.indexOf(state.greeting) + 1 < greetings.length ? greetings.indexOf(state.greeting) + 1 : 0;
        const dt = new Date()
        document.title = greetings[index]

        setState({
            greeting: greetings[index],
            time: `${dt.toLocaleDateString("es-MX")} ${dt.toLocaleTimeString("es-MX")}`
        })
    }, [state]);

    useEffect(() => {
        const intervalID = setInterval(timeShuffle, 1000);
        return () => clearInterval(intervalID);
    }, [timeShuffle])
    
    return <>
        <img src={pez2} className="logo" alt="logo" />
        <p className="special-text">{state.greeting}</p>
        {state.time}
    </>;
}