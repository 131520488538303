const greetings = [
    "Bienvenido!",
    "Welcome",
    "مَرْحَباً",
    "歡迎",
    "Dobrodošli!",
    "Vítejte!",
    "Velkommen",
    "Welkom!",
    "Bienvenue!",
    "Willkommen!",
    "Καλώς ήρθες",
    "ברוך הבא",
    "स्वागत हे",
    "Benvenuto!",
    "ようこそ！",
    "환영하다",
    "欢迎",
    "Velkommen!",
    "Witamy",
    "Bem-vindo!",
    "Bun venit!",
    "Välkommen!",
    "ยินดีต้อนรับ",
    "Hoş geldiniz",
]

export {
    greetings
}