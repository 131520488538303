import React, { useCallback, useEffect, useState } from "react";
import "./Armando.css";
import { Greeting } from "../../components";

export function Armando({}) {

    return <div className="container">
        <div className="home">
            <a href="https://pezatomico.com" className="link">Home</a>
        </div>
        <Greeting />
        <div className="sub-container">
            <span className="caption">
                curriculum vitae
            </span>
            <hr />
            <span>
                <a target="_blank" href="https://armando.pezatomico.com/cv" className="link">Armando González</a> (<a target="_blank" href="https://armando.pezatomico.com/cv-en" className="link">EN</a>)
            </span>
        </div>
        <div className="sub-container">
            <span className="caption">
                demos
            </span>
            <hr />
            <span>
                <a target="_blank" href="https://maps.pezatomico.com" className="link">Maps</a> demo
            </span>
            <span>
                <a target="_blank" href="https://drf.pezatomico.com/api" className="link">Django REST Framework</a> demo
            </span>
            <span>
                <a target="_blank" href="https://spring.pezatomico.com/items" className="link">Spring Boot API</a> demo
            </span>
        </div>
    </div>
}